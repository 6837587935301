import { useParams } from 'react-router-dom';
import {
  HeroGrid,
  HeroTypography, Paper,
  TypographyVariantEnum, TypographyWeightEnum,
} from '@uniqkey-frontend/shared-app';
// import PageRouteEnum from '../../../enums/PageRouteEnum';

interface IAutoActivationPageProps {
  email: string;
  code: number;
  name: string | undefined;
}

// TODO: ONBOARDING: WIP
const BrowserExtensionCheckPage = () => {
  // const { t } = useTranslations();

  const params = useParams();
  const { hash } = params;

  if (hash) {
    const decodedBase64 = atob(hash);
    const dataObject: IAutoActivationPageProps = JSON.parse(decodedBase64);
    // eslint-disable-next-line no-console
    console.log('hash', dataObject);
  }

  return (
    <HeroGrid container height="100vh" alignItems="center">
      <HeroGrid container className="height-100" flexWrap="nowrap">
        <HeroGrid
          container
          alignItems="center"
          justifyContent="center"
          flexDirection="column"
        >
          <Paper variant="outlined" color="gray.500">
            <HeroGrid
              container
              alignItems="center"
              justifyContent="center"
              flexDirection="column"
            >
              <HeroTypography
                variant={TypographyVariantEnum.LG}
                fontWeight={TypographyWeightEnum.Semibold}
              >
                Please wait a few seconds
              </HeroTypography>
              {/* eslint-disable-next-line max-len */}
              <HeroTypography variant={TypographyVariantEnum.MD}>
                Were checking if youve installed the browser extension...
              </HeroTypography>
            </HeroGrid>
          </Paper>
        </HeroGrid>
      </HeroGrid>
    </HeroGrid>
  );
};

export default BrowserExtensionCheckPage;
