import { useCallback, useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  PlusIcon,
  PanelContent,
  useHeroSnackbar,
  useTranslations,
  ActionButton,
  DeleteIcon,
  Tooltip,
  PromptModal,
  useMapKeyValueExtractor,
} from '@uniqkey-frontend/shared-app';
import useVerifiedDomainsAPI from '../../../../hooks/useVerifiedDomainsAPI';
import useVerifiedDomainsTable from '../../../../hooks/tables/useVerifiedDomainsTable';
import VerifiedDomainsTable, {
  IVerifiedDomainsTableRow,
} from '../../../../components/tables/VerifiedDomainsTable';
import AddVerifiedDomainModal, {
  IAddVerifiedDomainModalReturnValue,
} from './components/AddVerifiedDomainModal';
import { logException } from '../../../../services/sentryService';

const DOMAIN_ALREADY_EXISTS_ERROR = 'Such_VerifiedDomain_already_exists';

const VerifiedDomainsTab = () => {
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { createVerifiedDomain, deleteVerifiedDomains } = useVerifiedDomainsAPI();

  const [isAddVerifiedDomainModalOpen, setIsAddVerifiedDomainModalOpen] = useState(false);
  const handleAddVerifiedDomainModalOpen = useCallback(
    () => setIsAddVerifiedDomainModalOpen(true),
    [],
  );
  const handleAddVerifiedDomainModalClose = useCallback(
    () => setIsAddVerifiedDomainModalOpen(false),
    [],
  );

  const [isAddVerifiedDomainLoading, setIsAddVerifiedDomainLoading] = useState(false);

  const [isDeleteVerifiedDomainModalOpen, setIsDeleteVerifiedDomainModalOpen] = useState(false);
  const handleDeleteVerifiedDomainModalOpen = useCallback(
    () => setIsDeleteVerifiedDomainModalOpen(true),
    [],
  );
  const handleDeleteVerifiedDomainModalClose = useCallback(
    () => setIsDeleteVerifiedDomainModalOpen(false),
    [],
  );

  const [isDeleteVerifiedDomainLoading, setIsDeleteVerifiedDomainLoading] = useState(false);

  const {
    selectedVerifiedDomains,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useVerifiedDomainsTable({
    noDataMessageKey: 'settingsPage.verifiedDomainsTab.noData',
  });

  const {
    keys: selectedVerifiedDomainsIds,
  } = useMapKeyValueExtractor<IVerifiedDomainsTableRow>(selectedVerifiedDomains);

  const handleAddVerifiedDomain = useCallback(async (
    params: IAddVerifiedDomainModalReturnValue,
  ) => {
    const { domainName } = params;
    setIsAddVerifiedDomainLoading(true);
    try {
      await createVerifiedDomain({ domainName });
      showSuccess({
        text: t('addVerifiedDomainModal.verifiedDomainCreated'),
      });
      handleAddVerifiedDomainModalClose();
    } catch (e: any) {
      let key = 'common.somethingWentWrong';
      if (e?.response?.data?.includes(DOMAIN_ALREADY_EXISTS_ERROR)) {
        key = 'addVerifiedDomainModal.domainAlreadyExistsError';
      }
      showError({ text: t(key) });
      logException(e, {
        message: 'VerifiedDomainsTab/handleAddVerifiedDomain exception',
      });
    } finally {
      setIsAddVerifiedDomainLoading(false);
    }
  }, [createVerifiedDomain, handleAddVerifiedDomainModalClose, showError, showSuccess, t]);

  const handleDeleteVerifiedDomain = useCallback(async () => {
    setIsDeleteVerifiedDomainLoading(true);
    try {
      const { failCount, successCount } = await deleteVerifiedDomains(
        { verifiedDomainsIds: selectedVerifiedDomainsIds },
      );
      if (successCount) {
        showSuccess({
          text: t('deleteVerifiedDomainModal.successMessage', { count: successCount }),
        });
      }
      if (failCount) {
        showError({
          text: t('deleteVerifiedDomainModal.errorMessage', { count: failCount }),
        });
      }
      handleDeleteVerifiedDomainModalClose();
      resetSelectedRows();
      resetActivePage();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'VerifiedDomainsTab/handleDeleteVerifiedDomain exception',
      });
    } finally {
      setIsDeleteVerifiedDomainLoading(false);
    }
  }, [
    selectedVerifiedDomainsIds,
    deleteVerifiedDomains,
    handleDeleteVerifiedDomainModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          {!!selectedVerifiedDomains.size && (
            <>
              <Grid item alignSelf="center">
                <Tooltip title={t('common.delete')}>
                  <ActionButton
                    width={40}
                    height={40}
                    onClick={handleDeleteVerifiedDomainModalOpen}
                  >
                    <DeleteIcon />
                  </ActionButton>
                </Tooltip>
              </Grid>
              <Grid item my={0.5}>
                <Divider orientation="vertical" />
              </Grid>
            </>
          )}
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid>
            <Button
              icon={<PlusIcon />}
              onClick={handleAddVerifiedDomainModalOpen}
            >
              {t('settingsPage.verifiedDomainsTab.addVerifiedDomainButton')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <VerifiedDomainsTable
        selectedVerifiedDomains={selectedVerifiedDomains}
        {...restTableProps}
      />
      {isAddVerifiedDomainModalOpen && (
        <AddVerifiedDomainModal
          isOpen={isAddVerifiedDomainModalOpen}
          isLoading={isAddVerifiedDomainLoading}
          onClose={handleAddVerifiedDomainModalClose}
          onSubmit={handleAddVerifiedDomain}
        />
      )}
      {isDeleteVerifiedDomainModalOpen && (
        <PromptModal
          open={isDeleteVerifiedDomainModalOpen}
          onClose={handleDeleteVerifiedDomainModalClose}
          onSubmit={handleDeleteVerifiedDomain}
          isLoading={isDeleteVerifiedDomainLoading}
          title={t('deleteVerifiedDomainModal.title')}
          description={t('deleteVerifiedDomainModal.description')}
          approvalButtonText="common.delete"
        />
      )}
    </PanelContent>
  );
};

export default VerifiedDomainsTab;
