import { Suspense } from 'react';
import { ReactQueryDevtools } from 'react-query/devtools';
import '../helpers/ChartJSLib/config';
import { QueryClient, QueryClientProvider } from 'react-query';
import Router from './Router';
// import InitializationContainer from './components/InitializationContainer';

const ReactQueryClient = new QueryClient();

// TODO: ONBOARDING: WIP
const App = () => (
  <QueryClientProvider client={ReactQueryClient}>
    {/* <UserProviderContext> */}
    {/*   <APIClientsProviderContext> */}
    {/* <CompanionApplicationProviderContext> */}
    {/* <InitializationContainer> */}
    <Suspense fallback={null}>
      <Router />
    </Suspense>
    {/* </InitializationContainer> */}
    {/* </CompanionApplicationProviderContext> */}
    <ReactQueryDevtools />
    {/*   </APIClientsProviderContext> */}
    {/* </UserProviderContext> */}
  </QueryClientProvider>
);

export default App;
