import { ComponentType } from 'react';
import {
  HeroGrid,
  HeroPanel,
  ElevationEnum,
  HeroTypography,
  TypographyVariantEnum,
  TypographyWeightEnum,
  HeroLogoWithTextIcon,
} from '@uniqkey-frontend/shared-app';

interface IHeroSplitLayoutProps {
  RightContentComponent: ComponentType;
}

// TODO: ONBOARDING: WIP
const HeroSplitLayout = (props: IHeroSplitLayoutProps) => {
  // const { t } = useTranslations();

  const {
    RightContentComponent,
  } = props;
  return (
    <HeroGrid container flexDirection="column" height="100vh">
      <HeroGrid>
        <HeroPanel
          square
          className="height-100"
          elevation={ElevationEnum.NONE}
        >
          <HeroGrid container className="height-100" flexWrap="nowrap">
            <HeroGrid
              container
              flexBasis="50%"
              alignItems="center"
              justifyContent="center"
            >
              <HeroGrid
                container
                flexDirection="column"
              >
                <HeroLogoWithTextIcon height={65} width={115} />
                <HeroTypography
                  variant={TypographyVariantEnum.XL}
                  fontWeight={TypographyWeightEnum.Bold}
                >
                  Welcome, Johannes
                </HeroTypography>
                {/* eslint-disable-next-line max-len */}
                <HeroTypography variant={TypographyVariantEnum.MD}>
                  Lets get you started with Uniqkey!
                </HeroTypography>
              </HeroGrid>
            </HeroGrid>
            {/* <HeroDivider orientation="vertical" flexItem /> */}
            <HeroGrid>
              <RightContentComponent />
            </HeroGrid>
          </HeroGrid>
        </HeroPanel>
      </HeroGrid>
    </HeroGrid>
  );
};

export default HeroSplitLayout;
