import { useParams } from 'react-router-dom';
import HeroSplitLayout from '../../../layouts/HeroSplitLayout';
import BrowserExtensionCheckPage from '../BrowserExtensionCheckPage';
// import PageRouteEnum from '../../../enums/PageRouteEnum';

interface IAutoActivationPageProps {
  email: string;
  code: number;
  name: string | undefined;
}

// TODO: ONBOARDING: WIP
const AutoActivationPage = () => {
  // const { t } = useTranslations();

  const params = useParams();
  const { hash } = params;

  if (hash) {
    const decodedBase64 = atob(hash);
    const dataObject: IAutoActivationPageProps = JSON.parse(decodedBase64);
    // eslint-disable-next-line no-console
    console.log('hash', dataObject);
  }

  return (
    <HeroSplitLayout RightContentComponent={(BrowserExtensionCheckPage)} />
  );
};

export default AutoActivationPage;
