import { useCallback, useState, useMemo } from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  useTranslations,
  Tooltip,
  ActionButton,
  DeleteIcon,
  PromptModal,
  useMapKeyValueExtractor,
  useHeroSnackbar,
} from '@uniqkey-frontend/shared-app';
import usePartnersWithAccessTable from '../../../../hooks/tables/usePartnersWithAccessTable';
import PartnersWithAccessTable, {
  IPartnersWithAccessTableRow,
} from '../../../../components/tables/PartnersWithAccessTable';
import useOrganizationSettingsAPI from '../../../../hooks/useOrganizationSettingsAPI';
import { useUser } from '../../../../contexts/UserContext';

const PartnersWithAccessTab = () => {
  const { t } = useTranslations();
  const { showSuccess, showError } = useHeroSnackbar();
  const { revokePartnersAccess } = useOrganizationSettingsAPI();
  const { isSupporter } = useUser();

  const [
    isRevokePartnersAccessModalOpen,
    setIsRevokePartnersAccessModalOpen,
  ] = useState<boolean>(false);
  const [
    isRevokePartnersAccessLoading,
    setIsRevokePartnersAccessLoading,
  ] = useState<boolean>(false);

  const handleRevokePartnersAccessModalOpen = useCallback(
    () => setIsRevokePartnersAccessModalOpen(true),
    [],
  );
  const handleRevokePartnersAccessModalClose = useCallback(
    () => setIsRevokePartnersAccessModalOpen(false),
    [],
  );

  const {
    selectedPartners,
    resetQuery,
    resetSelectedRows,
    resetActivePage,
    ...restTableProps
  } = usePartnersWithAccessTable({
    noDataMessageKey: 'table.noData.default',
  });

  const {
    values: selectedPartnersAsObjects, keys: selectedPartnersIds,
  } = useMapKeyValueExtractor<IPartnersWithAccessTableRow>(selectedPartners);

  const TABLE_OPTIONS = useMemo(() => ({
    selection: !isSupporter,
  }), [isSupporter]);

  const handleRevokePartnersAccess = useCallback(async () => {
    try {
      setIsRevokePartnersAccessLoading(true);
      const { failedCount, successCount } = await revokePartnersAccess({
        partnerIds: selectedPartnersIds,
      });
      if (successCount) {
        showSuccess({
          text: t('revokePartnersAccessModal.successMessage', { count: successCount }),
        });
      }
      if (failedCount) {
        showError({
          text: t('revokePartnersAccessModal.errorMessage', { count: failedCount }),
        });
      }
      handleRevokePartnersAccessModalClose();
      resetSelectedRows();
      resetActivePage();
      resetQuery();
    } catch (e) {
      showError({ text: t('common.somethingWentWrong') });
    } finally {
      setIsRevokePartnersAccessLoading(false);
    }
  }, [
    revokePartnersAccess,
    selectedPartnersIds,
    handleRevokePartnersAccessModalClose,
    resetSelectedRows,
    resetActivePage,
    showSuccess,
    t,
    showError,
    resetQuery,
  ]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item alignSelf="center">
            <Tooltip title={t('settingsPage.partnersWithAccessTab.revokeAccessTooltip')}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleRevokePartnersAccessModalOpen}
                disabled={!selectedPartnersAsObjects.length}
              >
                <DeleteIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
      </Grid>
      <Divider />
      <PartnersWithAccessTable
        selectedPartners={selectedPartners}
        options={TABLE_OPTIONS}
        {...restTableProps}
      />
      {isRevokePartnersAccessModalOpen && (
        <PromptModal
          open={isRevokePartnersAccessModalOpen}
          onClose={handleRevokePartnersAccessModalClose}
          onSubmit={handleRevokePartnersAccess}
          title={t('revokePartnersAccessModal.title', { count: selectedPartners.size })}
          description={t('revokePartnersAccessModal.description')}
          list={selectedPartnersAsObjects}
          renderField="partnerName"
          renderKey="partnerId"
          isLoading={isRevokePartnersAccessLoading}
        />
      )}
    </PanelContent>
  );
};

export default PartnersWithAccessTab;
