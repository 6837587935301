import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { useHeroSnackbar } from '@uniqkey-frontend/shared-app';
import OnboardingPageRouteEnum from './enums/OnboardingPageRouteEnum';
import AutoActivationPage from './pages/AutoActivationPage';

// TODO: ONBOARDING: WIP
const Router = () => {
  const { getSnackbar } = useHeroSnackbar();

  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={OnboardingPageRouteEnum.AutoActivation}
          element={<AutoActivationPage />}
        />
      </Routes>
      {getSnackbar()}
    </BrowserRouter>
  );
};

export default Router;
