import {
  useCallback, useState, memo, useMemo,
} from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  SearchableTextField,
  useTranslations,
  Button,
  PlusIcon,
  useHeroSnackbar,
  Tooltip,
  ActionButton,
  MoveIcon,
  usePopper,
  FilterButton,
  ShareIcon,
  encryptSymmetric,
  ErrorIcon,
  useMapKeyValueExtractor,
  PromptModal,
  Typography,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  MoveToEmployeeIcon,
  MoveToGroupIcon,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus, Ownership } from '@uniqkey-backend-organization-web/api-client';
import useEmployeeGroupSecureNotesTable, {
  IEmployeeGroupSecureNotesTableRow,
} from '../../../../hooks/tables/useEmployeeGroupSecureNotesTable';
import EmployeeSecureNotesTable from '../../../../components/tables/EmployeeSecureNotesTable';
import CreateSecureNoteModal, {
  ICreateSecureNoteReturnValue,
} from '../../../../components/CreateSecureNoteModal';
import useVaultsToEmployeeAccountsAPI from '../../../../hooks/useVaultsToEmployeeAccountsAPI';
import {
  useDeleteVaultsToEmployeeAccounts,
  useGetEmployeeAccountById,
} from '../../../../hooks/reactQuery';
import EmployeeSecureNotesTabFilter, {
  IEmployeeSecureNotesTabFilterSubmitResult,
} from './components/EmployeeSecureNotesTabFilter';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import useVaultsAPI from '../../../../hooks/useVaultsAPI';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import MoveVaultsToEmployeeModal from '../../../../components/MoveVaultsToEmployeeModal';
import MoveVaultsToGroupModal from '../../../../components/MoveVaultsToGroupModal';
import { getTranslationKeyByError } from '../../../../helpers/errorService';

interface IEmployeeSecureNotesTabProps {
  employeeAccountId: string;
  employeeAccountStatus: EmployeeAccountStatus;
}

const EmployeeSecureNotesTab = (props: IEmployeeSecureNotesTabProps) => {
  const { employeeAccountId, employeeAccountStatus } = props;
  const { t } = useTranslations();
  const [isCreateSecureNoteModalOpen, setIsCreateSecureNoteModalOpen] = useState(false);
  const [isCreateSecureNoteLoading, setIsCreateSecureNoteLoading] = useState(false);
  const [isDeleteSecureNoteModalOpen, setIsDeleteSecureNoteModalOpen] = useState(false);
  const [isDeleteSecureNoteLoading, setIsDeleteSecureNoteLoading] = useState(false);
  const [isMoveToPrivateSecureNotesModalOpen, setIsMoveToPrivateSecureNotesModalOpen] = useState(
    false,
  );
  const [isMoveToPrivateSecureNotesLoading, setIsMoveToPrivateSecureNotesLoading] = useState(false);
  const [isShareSecureNotesModalOpen, setIsShareSecureNotesModalOpen] = useState(false);
  const [isMoveToEmployeeSecureNotesModalOpen, setIsMoveToEmployeeSecureNotesModalOpen] = useState(
    false,
  );
  const [isMoveToGroupSecureNotesModalOpen, setIsMoveToGroupSecureNotesModalOpen] = useState(
    false,
  );

  const handleCreateSecureNoteModalOpen = useCallback(
    () => setIsCreateSecureNoteModalOpen(true),
    [],
  );
  const handleCreateSecureNoteModalClose = useCallback(
    () => setIsCreateSecureNoteModalOpen(false),
    [],
  );
  const handleDeleteSecureNoteModalOpen = useCallback(
    () => setIsDeleteSecureNoteModalOpen(true),
    [],
  );
  const handleDeleteSecureNoteModalClose = useCallback(
    () => setIsDeleteSecureNoteModalOpen(false),
    [],
  );
  const handleMoveToPrivateSecureNotesModalOpen = useCallback(
    () => setIsMoveToPrivateSecureNotesModalOpen(true),
    [],
  );
  const handleMoveToPrivateSecureNotesModalClose = useCallback(
    () => setIsMoveToPrivateSecureNotesModalOpen(false),
    [],
  );
  const handleShareSecureNotesModalOpen = useCallback(
    () => setIsShareSecureNotesModalOpen(true),
    [],
  );
  const handleShareSecureNotesModalClose = useCallback(
    () => setIsShareSecureNotesModalOpen(false),
    [],
  );
  const handleMoveToEmployeeSecureNotesModalOpen = useCallback(
    () => setIsMoveToEmployeeSecureNotesModalOpen(true),
    [],
  );
  const handleMoveToEmployeeSecureNotesModalClose = useCallback(
    () => setIsMoveToEmployeeSecureNotesModalOpen(false),
    [],
  );
  const handleMoveToGroupSecureNotesModalOpen = useCallback(
    () => setIsMoveToGroupSecureNotesModalOpen(true),
    [],
  );
  const handleMoveToGroupSecureNotesModalClose = useCallback(
    () => setIsMoveToGroupSecureNotesModalOpen(false),
    [],
  );

  const { showError, showWarning, showSuccess } = useHeroSnackbar();
  const { createVaultsSecureNoteToEmployeeAccounts } = useVaultsToEmployeeAccountsAPI();
  const { moveVaultsToPrivateKeychain } = useVaultsAPI();
  const { mutate: mutateDeleteSecureNote } = useDeleteVaultsToEmployeeAccounts();
  const { symmetricKey } = useCompanionApplicationContext();
  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );
  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const {
    selectedSecureNotes,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    resetActivePage,
    resetSelectedRows,
    ...restTableProps
  } = useEmployeeGroupSecureNotesTable({
    persistentFilters: { employeeAccountId },
    noDataMessageKey: 'secureNotesTab.table.noData',
  });

  const { data: employeeAccount } = useGetEmployeeAccountById(
    { employeeAccountId },
  );

  const {
    values: selectedSecureNotesAsObjects, keys: selectedSecureNotesIds,
  } = useMapKeyValueExtractor<IEmployeeGroupSecureNotesTableRow>(selectedSecureNotes);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    isMoveToPrivateDisabled,
    isMoveToEmployeeDisabled,
    isMoveToGroupDisabled,
    isShareDisabled,
    isRemoveDisabled,
  } = useMemo(() => {
    if (!selectedSecureNotesAsObjects.length) {
      return {
        isMoveToPrivateDisabled: true,
        isMoveToEmployeeDisabled: true,
        isMoveToGroupDisabled: true,
        isShareDisabled: true,
        isRemoveDisabled: true,
      };
    }
    let disableMoveToPrivate = false;
    let disableShare = false;
    let disableRemove = false;
    selectedSecureNotesAsObjects.forEach(({ ownership }) => {
      if (ownership === Ownership.Groups) {
        disableMoveToPrivate = true;
        disableRemove = true;
      }
      if (ownership === Ownership.Employees) {
        disableShare = true;
      }
    });
    return {
      isMoveToPrivateDisabled: disableMoveToPrivate,
      isMoveToEmployeeDisabled: false,
      isMoveToGroupDisabled: false,
      isShareDisabled: disableShare,
      isRemoveDisabled: disableRemove,
    };
  }, [selectedSecureNotesAsObjects]);

  const handleCreateSecureNote = useCallback(async (params: ICreateSecureNoteReturnValue) => {
    const { name, note } = params;
    setIsCreateSecureNoteLoading(true);
    try {
      await createVaultsSecureNoteToEmployeeAccounts({
        employeeAccountId,
        name,
        note: encryptSymmetric({
          key: symmetricKey,
          string: note,
          fallbackValue: null,
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.secureNote.created'),
        });
      } else {
        showWarning({
          text: t('createSecureNoteModal.approvalOnMobileToast'),
        });
      }
      handleCreateSecureNoteModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeeSecureNotesTab/handleCreateSecureNote exception',
      });
    } finally {
      setIsCreateSecureNoteLoading(false);
    }
  }, [
    createVaultsSecureNoteToEmployeeAccounts,
    handleCreateSecureNoteModalClose,
    employeeAccountId,
    symmetricKey,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleDeleteSecureNote = useCallback(async () => {
    setIsDeleteSecureNoteLoading(true);
    mutateDeleteSecureNote({
      vaultIds: selectedSecureNotesIds, employeeAccountId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteSecureNoteFromEmployeeModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteSecureNoteFromEmployeeModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteSecureNoteModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t(getTranslationKeyByError(e)) });
        logException(e, {
          message: 'EmployeeSecureNotesTab/handleDeleteSecureNote exception',
        });
      },
      onSettled: () => setIsDeleteSecureNoteLoading(false),
    });
  }, [
    employeeAccountId,
    selectedSecureNotesIds,
    mutateDeleteSecureNote,
    handleDeleteSecureNoteModalClose,
    showError,
    showSuccess,
    resetSelectedRows,
    resetActivePage,
    t,
  ]);

  const handleMoveToPrivateSecureNotes = useCallback(async () => {
    try {
      setIsMoveToPrivateSecureNotesLoading(true);
      await moveVaultsToPrivateKeychain({ vaultIds: selectedSecureNotesIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.secureNote.moved'),
        });
      } else {
        showWarning({
          text: t('moveSecureNotesModal.approvalOnMobileToast'),
        });
      }
      resetSelectedRows();
      handleMoveToPrivateSecureNotesModalClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      logException(e, {
        message: 'EmployeeSecureNotesTab/handleMoveToPrivateSecureNotes exception',
      });
    } finally {
      setIsMoveToPrivateSecureNotesLoading(false);
    }
  }, [
    moveVaultsToPrivateKeychain,
    selectedSecureNotesIds,
    isTrustedPortalEnabled,
    showWarning,
    showError,
    showSuccess,
    handleMoveToPrivateSecureNotesModalClose,
    resetSelectedRows,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const handleFilterSubmit = useCallback((
    updatedValues: IEmployeeSecureNotesTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const moveSecureNotesToPrivateModalDescriptionElement = useMemo(
    () => (
      <>
        <Typography>{t('moveSecureNotesToPrivateKeychainModal.description')}</Typography>
        <Typography>{t('moveSecureNotesToPrivateKeychainModal.additionalDescription')}</Typography>
      </>
    ),
    [t],
  );

  const {
    moveToPrivateTooltipTitle,
    moveToEmployeeTooltipTitle,
    moveToGroupTooltipTitle,
    shareTooltipTitle,
    removeTooltipTitle,
  } = useMemo(() => {
    const moveToPrivateTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotesAsObjects.length,
      t,
      isDisabled: isMoveToPrivateDisabled,
      key: 'secureNotesTab.moveToPrivate',
    });
    const moveToEmployeeTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotesAsObjects.length,
      t,
      isDisabled: isMoveToEmployeeDisabled,
      key: 'secureNotesTab.moveToEmployee',
    });
    const moveToGroupTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotesAsObjects.length,
      t,
      isDisabled: false,
      key: 'secureNotesTab.moveToGroup',
    });
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotesAsObjects.length,
      t,
      isDisabled: isShareDisabled,
      key: 'secureNotesTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedSecureNotesAsObjects.length,
      t,
      isDisabled: isRemoveDisabled,
      key: 'secureNotesTab.remove',
    });

    return {
      moveToPrivateTooltipTitle: moveToPrivateTitle,
      moveToEmployeeTooltipTitle: moveToEmployeeTitle,
      moveToGroupTooltipTitle: moveToGroupTitle,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [
    selectedSecureNotesAsObjects.length,
    t,
    isMoveToPrivateDisabled,
    isMoveToEmployeeDisabled,
    isShareDisabled,
    isRemoveDisabled,
  ]);

  const isCreateSecureNoteShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
  ), [employeeAccountStatus]);

  const isMoveToPrivateSecureNoteShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
    && employeeAccountStatus !== EmployeeAccountStatus.Staged
    && employeeAccountStatus !== EmployeeAccountStatus.Invited
    && employeeAccountStatus !== EmployeeAccountStatus.Migrated
    && employeeAccountStatus !== EmployeeAccountStatus.MigrationInvited
  ), [employeeAccountStatus]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          {isMoveToPrivateSecureNoteShown && (
            <Grid item alignSelf="center">
              <Tooltip title={moveToPrivateTooltipTitle}>
                <ActionButton
                  width={40}
                  height={40}
                  onClick={handleMoveToPrivateSecureNotesModalOpen}
                  disabled={isMoveToPrivateDisabled}
                >
                  <MoveIcon />
                </ActionButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item alignSelf="center">
            <Tooltip title={moveToEmployeeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToEmployeeSecureNotesModalOpen}
                disabled={isMoveToEmployeeDisabled}
              >
                <MoveToEmployeeIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={moveToGroupTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToGroupSecureNotesModalOpen}
                disabled={isMoveToGroupDisabled}
              >
                <MoveToGroupIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareSecureNotesModalOpen}
                disabled={isShareDisabled}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteSecureNoteModalOpen}
                disabled={isRemoveDisabled}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          {isCreateSecureNoteShown && (
            <Grid item ml={3}>
              <Button
                icon={<PlusIcon />}
                onClick={handleCreateSecureNoteModalOpen}
              >
                {t('secureNotesTab.createSecureNoteButton')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider />
      <EmployeeSecureNotesTable
        selectedSecureNotes={selectedSecureNotes}
        {...restTableProps}
      />
      <EmployeeSecureNotesTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isCreateSecureNoteModalOpen && (
        <CreateSecureNoteModal
          isOpen={isCreateSecureNoteModalOpen}
          onClose={handleCreateSecureNoteModalClose}
          onSubmit={handleCreateSecureNote}
          isLoading={isCreateSecureNoteLoading}
        />
      )}
      {isDeleteSecureNoteModalOpen && (
        <PromptModal
          open={isDeleteSecureNoteModalOpen}
          onClose={handleDeleteSecureNoteModalClose}
          onSubmit={handleDeleteSecureNote}
          title={t('deleteSecureNoteFromEmployeeModal.title', { count: selectedSecureNotes.size })}
          description={t(
            'deleteSecureNoteFromEmployeeModal.description',
            { email: employeeAccount?.email },
          )}
          additionalDescription={t('deleteSecureNoteFromEmployeeModal.additionalDescription')}
          list={selectedSecureNotesAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteSecureNoteLoading}
        />
      )}
      {isMoveToPrivateSecureNotesModalOpen && (
        <PromptModal
          open={isMoveToPrivateSecureNotesModalOpen}
          onClose={handleMoveToPrivateSecureNotesModalClose}
          onSubmit={handleMoveToPrivateSecureNotes}
          title={t(
            'moveSecureNotesToPrivateKeychainModal.title',
            { count: selectedSecureNotes.size },
          )}
          description={moveSecureNotesToPrivateModalDescriptionElement}
          list={selectedSecureNotesAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isMoveToPrivateSecureNotesLoading}
          approvalButtonText="common.move"
          approvalButtonColor="primary"
        />
      )}
      {isMoveToEmployeeSecureNotesModalOpen && (
        <MoveVaultsToEmployeeModal
          isOpen={isMoveToEmployeeSecureNotesModalOpen}
          onClose={handleMoveToEmployeeSecureNotesModalClose}
          vaults={selectedSecureNotesAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.SecureNote}
        />
      )}
      {isMoveToGroupSecureNotesModalOpen && (
        <MoveVaultsToGroupModal
          isOpen={isMoveToGroupSecureNotesModalOpen}
          onClose={handleMoveToGroupSecureNotesModalClose}
          vaults={selectedSecureNotesAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.SecureNote}
        />
      )}
      {isShareSecureNotesModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareSecureNotesModalOpen}
          onClose={handleShareSecureNotesModalClose}
          vaults={selectedSecureNotesAsObjects}
          type={VaultTypeEnum.SecureNote}
          onSubmit={resetSelectedRows}
        />
      )}
    </PanelContent>
  );
};

export default memo(EmployeeSecureNotesTab);
