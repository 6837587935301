import {
  memo, useState, useCallback, useMemo,
} from 'react';
import {
  Divider,
  Grid,
  PanelContent,
  SearchableTextField,
  useTranslations,
  Button,
  PlusIcon,
  Tooltip,
  FilterButton,
  useMapKeyValueExtractor,
  useHeroSnackbar,
  ActionButton,
  ErrorIcon,
  PromptModal,
  usePopper,
  MoveIcon,
  ShareIcon,
  Typography,
  encryptSymmetric,
  ISearchableTextFieldProps,
  IFilterButtonProps,
  MoveToEmployeeIcon,
  MoveToGroupIcon,
} from '@uniqkey-frontend/shared-app';
import { EmployeeAccountStatus, Ownership } from '@uniqkey-backend-organization-web/api-client';
import EmployeeCreditCardsTable from '../../../../components/tables/EmployeeCreditCardsTable';
import useEmployeeGroupCreditCardsTable, { IEmployeeGroupCreditCardsTableRow } from
  '../../../../hooks/tables/useEmployeeGroupCreditCardsTable';
import EmployeeCreditCardsTabFilter, {
  IEmployeeCreditCardsTabFilterSubmitResult,
} from './components/EmployeeCreditCardsTabFilter';
import {
  useGetEmployeeAccountById,
  useDeleteVaultsToEmployeeAccounts,
} from '../../../../hooks/reactQuery';
import { logException } from '../../../../services/sentryService';
import { getActiveOrganizationId } from '../../../../services/organizationService';
import { useTrustedPortalStore } from '../../../../modules/TrustedPortalModule/store';
import useVaultsAPI from '../../../../hooks/useVaultsAPI';
import CreateCreditCardModal, {
  ICreateCreditCardReturnValue,
} from '../../../../components/CreateCreditCardModal';
import useVaultsToEmployeeAccountsAPI from '../../../../hooks/useVaultsToEmployeeAccountsAPI';
import { useCompanionApplicationContext } from '../../../../contexts/CompanionApplicationContext';
import { generateTooltipTitle } from '../../../../helpers/tooltips';
import ShareVaultsToGroupModal from '../../../../components/ShareVaultsToGroupModal';
import VaultTypeEnum from '../../../../enums/VaultTypeEnum';
import MoveVaultsToEmployeeModal from '../../../../components/MoveVaultsToEmployeeModal';
import MoveVaultsToGroupModal from '../../../../components/MoveVaultsToGroupModal';
import { getTranslationKeyByError } from '../../../../helpers/errorService';

interface IEmployeeCreditCardsTabProps {
  employeeAccountId: string;
  employeeAccountStatus: EmployeeAccountStatus;
}

const EmployeeCreditCardsTab = (props: IEmployeeCreditCardsTabProps) => {
  const { employeeAccountId, employeeAccountStatus } = props;
  const { t } = useTranslations();
  const { showError, showWarning, showSuccess } = useHeroSnackbar();
  const { symmetricKey } = useCompanionApplicationContext();
  const { createVaultsCreditCardToEmployeeAccounts } = useVaultsToEmployeeAccountsAPI();
  const { moveVaultsToPrivateKeychain } = useVaultsAPI();
  const { mutate: mutateDeleteCreditCard } = useDeleteVaultsToEmployeeAccounts();
  const [isDeleteCreditCardModalOpen, setIsDeleteCreditCardModalOpen] = useState(false);
  const [isDeleteCreditCardLoading, setIsDeleteCreditCardLoading] = useState(false);
  const [isMoveToPrivateCreditCardsModalOpen, setIsMoveToPrivateCreditCardsModalOpen] = useState(
    false,
  );
  const [isMoveToPrivateCreditCardsLoading, setIsMoveToPrivateCreditCardsLoading] = useState(false);
  const [isMoveToEmployeeCreditCardsModalOpen, setIsMoveToEmployeeCreditCardsModalOpen] = useState(
    false,
  );
  const [isShareCreditCardsModalOpen, setIsShareCreditCardsModalOpen] = useState(false);
  const [isCreateCreditCardModalOpen, setIsCreateCreditCardModalOpen] = useState(false);
  const [isCreateCreditCardLoading, setIsCreateCreditCardLoading] = useState(false);
  const [isMoveToGroupCreditCardsModalOpen, setIsMoveToGroupCreditCardsModalOpen] = useState(
    false,
  );

  const handleDeleteCreditCardModalOpen = useCallback(
    () => setIsDeleteCreditCardModalOpen(true),
    [],
  );
  const handleDeleteCreditCardModalClose = useCallback(
    () => setIsDeleteCreditCardModalOpen(false),
    [],
  );
  const handleMoveToPrivateCreditCardsModalOpen = useCallback(
    () => setIsMoveToPrivateCreditCardsModalOpen(true),
    [],
  );
  const handleMoveToPrivateCreditCardsModalClose = useCallback(
    () => setIsMoveToPrivateCreditCardsModalOpen(false),
    [],
  );
  const handleShareCreditCardsModalOpen = useCallback(
    () => setIsShareCreditCardsModalOpen(true),
    [],
  );
  const handleShareCreditCardsModalClose = useCallback(
    () => setIsShareCreditCardsModalOpen(false),
    [],
  );
  const handleCreateCreditCardModalOpen = useCallback(
    () => setIsCreateCreditCardModalOpen(true),
    [],
  );
  const handleCreateCreditCardModalClose = useCallback(
    () => setIsCreateCreditCardModalOpen(false),
    [],
  );
  const handleMoveToEmployeeCreditCardsModalOpen = useCallback(
    () => setIsMoveToEmployeeCreditCardsModalOpen(true),
    [],
  );
  const handleMoveToEmployeeCreditCardsModalClose = useCallback(
    () => setIsMoveToEmployeeCreditCardsModalOpen(false),
    [],
  );
  const handleMoveToGroupCreditCardsModalOpen = useCallback(
    () => setIsMoveToGroupCreditCardsModalOpen(true),
    [],
  );
  const handleMoveToGroupCreditCardsModalClose = useCallback(
    () => setIsMoveToGroupCreditCardsModalOpen(false),
    [],
  );

  const {
    isOpen: isFilterOpen,
    anchorEl: filterAnchorEl,
    setPopperIsOpen: setIsFilterOpen,
  } = usePopper();
  const toggleIsFilterOpen = useCallback<NonNullable<IFilterButtonProps['onChange']>>(
    (event) => setIsFilterOpen(!isFilterOpen, event),
    [setIsFilterOpen, isFilterOpen],
  );

  const {
    selectedCreditCards,
    resetSelectedRows,
    resetActivePage,
    searchQuery,
    setSearchQuery,
    filterValues,
    setFilterValues,
    isFilterActive,
    numberOfActiveFilters,
    ...restTableProps
  } = useEmployeeGroupCreditCardsTable({
    persistentFilters: { employeeAccountId },
    noDataMessageKey: 'creditCardsTab.table.noData',
  });

  const handleFilterClose = useCallback(() => setIsFilterOpen(false), [setIsFilterOpen]);

  const handleFilterSubmit = useCallback((
    updatedValues: IEmployeeCreditCardsTabFilterSubmitResult,
  ) => {
    setFilterValues(updatedValues);
    resetActivePage();
  }, [setFilterValues, resetActivePage]);

  const { data: employeeAccount } = useGetEmployeeAccountById(
    { employeeAccountId },
  );

  const {
    values: selectedCreditCardsAsObjects, keys: selectedCreditCardsIds,
  } = useMapKeyValueExtractor<IEmployeeGroupCreditCardsTableRow>(selectedCreditCards);

  const activeOrganizationId = getActiveOrganizationId();
  const isTrustedPortalEnabled = useTrustedPortalStore.useIsEnabledByOrganizationId()[
    activeOrganizationId!
  ] ?? false;

  const {
    isMoveToPrivateDisabled,
    isMoveToEmployeeDisabled,
    isMoveToGroupDisabled,
    isShareDisabled,
    isRemoveDisabled,
  } = useMemo(() => {
    if (!selectedCreditCardsAsObjects.length) {
      return {
        isMoveToPrivateDisabled: true,
        isMoveToEmployeeDisabled: true,
        isMoveToGroupDisabled: true,
        isShareDisabled: true,
        isRemoveDisabled: true,
      };
    }
    let disableMoveToPrivate = false;
    let disableShare = false;
    let disableRemove = false;
    selectedCreditCardsAsObjects.forEach(({ ownership }) => {
      if (ownership === Ownership.Groups) {
        disableMoveToPrivate = true;
        disableRemove = true;
      }
      if (ownership === Ownership.Employees) {
        disableShare = true;
      }
    });
    return {
      isMoveToPrivateDisabled: disableMoveToPrivate,
      isMoveToEmployeeDisabled: false,
      isMoveToGroupDisabled: false,
      isShareDisabled: disableShare,
      isRemoveDisabled: disableRemove,
    };
  }, [selectedCreditCardsAsObjects]);

  const handleDeleteCreditCard = useCallback(async () => {
    setIsDeleteCreditCardLoading(true);
    mutateDeleteCreditCard({
      vaultIds: selectedCreditCardsIds, employeeAccountId,
    }, {
      onSuccess: ({ failCount, successCount }) => {
        if (successCount) {
          showSuccess({
            text: t('deleteCreditCardModal.successMessage', { count: successCount }),
          });
        }
        if (failCount) {
          showError({
            text: t('deleteCreditCardModal.errorMessage', { count: failCount }),
          });
        }
        handleDeleteCreditCardModalClose();
        resetSelectedRows();
        resetActivePage();
      },
      onError: (e) => {
        showError({ text: t(getTranslationKeyByError(e)) });
        logException(e, { message: 'EmployeeCreditCardsTab/handleDeleteCreditCard exception' });
      },
      onSettled: () => setIsDeleteCreditCardLoading(false),
    });
  }, [
    employeeAccountId,
    selectedCreditCardsIds,
    mutateDeleteCreditCard,
    handleDeleteCreditCardModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showSuccess,
    t,
  ]);

  const handleMoveToPrivateCreditCards = useCallback(async () => {
    try {
      setIsMoveToPrivateCreditCardsLoading(true);
      await moveVaultsToPrivateKeychain({ vaultIds: selectedCreditCardsIds });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.creditCard.moved'),
        });
      } else {
        showWarning({
          text: t('moveCreditCardsModal.approvalOnMobileToast'),
        });
      }
      resetSelectedRows();
      resetActivePage();
      handleMoveToPrivateCreditCardsModalClose();
    } catch (e) {
      showError({ text: t(getTranslationKeyByError(e)) });
      logException(e, {
        message: 'EmployeeCreditCardsTab/handleMoveToPrivateCreditCards exception',
      });
    } finally {
      setIsMoveToPrivateCreditCardsLoading(false);
    }
  }, [
    moveVaultsToPrivateKeychain,
    selectedCreditCardsIds,
    handleMoveToPrivateCreditCardsModalClose,
    resetSelectedRows,
    resetActivePage,
    showError,
    showWarning,
    showSuccess,
    isTrustedPortalEnabled,
    t,
  ]);

  const handleCreateCreditCard = useCallback(async (params: ICreateCreditCardReturnValue) => {
    const {
      name, cardNumber, expiryDate, cvv, cardHolderName,
    } = params;
    setIsCreateCreditCardLoading(true);
    try {
      await createVaultsCreditCardToEmployeeAccounts({
        employeeAccountId,
        name,
        cardNumber: encryptSymmetric({
          key: symmetricKey,
          string: cardNumber,
        }),
        expiryDate: encryptSymmetric({
          key: symmetricKey,
          string: expiryDate,
          fallbackValue: '',
        }),
        cardHolderName: encryptSymmetric({
          key: symmetricKey,
          string: cardHolderName,
          fallbackValue: '',
        }),
        cvv: encryptSymmetric({
          key: symmetricKey,
          string: cvv,
          fallbackValue: '',
        }),
      });
      if (isTrustedPortalEnabled) {
        showSuccess({
          text: t('trustedPortalSuccessNotifications.creditCard.created'),
        });
      } else {
        showWarning({
          text: t('createCreditCardModal.approvalOnMobileToast'),
        });
      }
      handleCreateCreditCardModalClose();
    } catch (e) {
      showError({
        text: t('common.somethingWentWrong'),
      });
      logException(e, {
        message: 'EmployeeCreditCardsTab/handleCreateCreditCard exception',
      });
    } finally {
      setIsCreateCreditCardLoading(false);
    }
  }, [
    createVaultsCreditCardToEmployeeAccounts,
    handleCreateCreditCardModalClose,
    employeeAccountId,
    symmetricKey,
    isTrustedPortalEnabled,
    showSuccess,
    showWarning,
    showError,
    t,
  ]);

  const handleSearchChange = useCallback<ISearchableTextFieldProps['onChange']>(
    (debouncedValue) => {
      setSearchQuery(debouncedValue);
      resetActivePage();
    },
    [setSearchQuery, resetActivePage],
  );

  const moveToPrivateCreditCardsModalDescriptionElement = useMemo(
    () => (
      <>
        <Typography component="span">
          {t('moveCreditCardsToPrivateKeychainModal.description')}
        </Typography>
        <Typography component="span">
          {t('moveCreditCardsToPrivateKeychainModal.additionalDescription')}
        </Typography>
      </>
    ),
    [t],
  );

  const {
    moveToPrivateTooltipTitle,
    moveToEmployeeTooltipTitle,
    moveToGroupTooltipTitle,
    shareTooltipTitle,
    removeTooltipTitle,
  } = useMemo(() => {
    const moveToPrivateTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCardsAsObjects.length,
      t,
      isDisabled: isMoveToPrivateDisabled,
      key: 'creditCardsTab.moveToPrivate',
    });
    const moveToEmployeeTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCardsAsObjects.length,
      t,
      isDisabled: isMoveToEmployeeDisabled,
      key: 'creditCardsTab.moveToEmployee',
    });
    const moveToGroupTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCardsAsObjects.length,
      t,
      isDisabled: isMoveToGroupDisabled,
      key: 'creditCardsTab.moveToGroup',
    });
    const shareTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCardsAsObjects.length,
      t,
      isDisabled: isShareDisabled,
      key: 'creditCardsTab.share',
    });
    const removeTitle = generateTooltipTitle({
      selectedDataLength: selectedCreditCardsAsObjects.length,
      t,
      isDisabled: isRemoveDisabled,
      key: 'creditCardsTab.remove',
    });

    return {
      moveToPrivateTooltipTitle: moveToPrivateTitle,
      moveToEmployeeTooltipTitle: moveToEmployeeTitle,
      moveToGroupTooltipTitle: moveToGroupTitle,
      shareTooltipTitle: shareTitle,
      removeTooltipTitle: removeTitle,
    };
  }, [
    selectedCreditCardsAsObjects.length,
    t,
    isMoveToPrivateDisabled,
    isMoveToEmployeeDisabled,
    isMoveToGroupDisabled,
    isShareDisabled,
    isRemoveDisabled,
  ]);

  const isCreateCreditCardShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
  ), [employeeAccountStatus]);

  const isMoveToPrivateCreditCardShown = useMemo(() => (
    employeeAccountStatus !== EmployeeAccountStatus.Archived
    && employeeAccountStatus !== EmployeeAccountStatus.Staged
    && employeeAccountStatus !== EmployeeAccountStatus.Invited
    && employeeAccountStatus !== EmployeeAccountStatus.Migrated
    && employeeAccountStatus !== EmployeeAccountStatus.MigrationInvited
  ), [employeeAccountStatus]);

  return (
    <PanelContent p={0}>
      <Grid container justifyContent="space-between" alignItems="stretch" p={1}>
        <Grid item xs={4} container flexWrap="nowrap" spacing={1}>
          <Grid item>
            <Tooltip title={t('common.filter')}>
              <FilterButton
                isFilterActive={isFilterActive}
                numberOfActiveFilters={numberOfActiveFilters}
                selected={isFilterOpen}
                onChange={toggleIsFilterOpen}
              />
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
          {isMoveToPrivateCreditCardShown && (
            <Grid item alignSelf="center">
              <Tooltip title={moveToPrivateTooltipTitle}>
                <ActionButton
                  width={40}
                  height={40}
                  onClick={handleMoveToPrivateCreditCardsModalOpen}
                  disabled={isMoveToPrivateDisabled}
                >
                  <MoveIcon />
                </ActionButton>
              </Tooltip>
            </Grid>
          )}
          <Grid item alignSelf="center">
            <Tooltip title={moveToEmployeeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToEmployeeCreditCardsModalOpen}
                disabled={isMoveToEmployeeDisabled}
              >
                <MoveToEmployeeIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={moveToGroupTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleMoveToGroupCreditCardsModalOpen}
                disabled={isMoveToGroupDisabled}
              >
                <MoveToGroupIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={shareTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleShareCreditCardsModalOpen}
                disabled={isShareDisabled}
              >
                <ShareIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item alignSelf="center">
            <Tooltip title={removeTooltipTitle}>
              <ActionButton
                width={40}
                height={40}
                onClick={handleDeleteCreditCardModalOpen}
                disabled={isRemoveDisabled}
              >
                <ErrorIcon />
              </ActionButton>
            </Tooltip>
          </Grid>
          <Grid item my={0.5}>
            <Divider orientation="vertical" />
          </Grid>
        </Grid>
        <Grid item xs={8} container justifyContent="flex-end" flexWrap="nowrap">
          <Grid item>
            <SearchableTextField
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder={t('common.search')}
            />
          </Grid>
          {isCreateCreditCardShown && (
            <Grid item ml={3}>
              <Button
                icon={<PlusIcon />}
                onClick={handleCreateCreditCardModalOpen}
              >
                {t('creditCardsTab.createCreditCardButton')}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
      <Divider />
      <EmployeeCreditCardsTable
        selectedCreditCards={selectedCreditCards}
        {...restTableProps}
      />
      <EmployeeCreditCardsTabFilter
        isOpen={isFilterOpen}
        anchorEl={filterAnchorEl}
        onSubmit={handleFilterSubmit}
        onClose={handleFilterClose}
        initialValues={filterValues}
      />
      {isDeleteCreditCardModalOpen && (
        <PromptModal
          open={isDeleteCreditCardModalOpen}
          onClose={handleDeleteCreditCardModalClose}
          onSubmit={handleDeleteCreditCard}
          title={t(
            'deleteCreditCardModal.deleteFromEmployee.title',
            { count: selectedCreditCards.size },
          )}
          description={t(
            'deleteCreditCardModal.deleteFromEmployee.description',
            { email: employeeAccount?.email },
          )}
          additionalDescription={t(
            'deleteCreditCardModal.deleteFromEmployee.additionalDescription',
          )}
          list={selectedCreditCardsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isDeleteCreditCardLoading}
        />
      )}
      {isMoveToPrivateCreditCardsModalOpen && (
        <PromptModal
          open={isMoveToPrivateCreditCardsModalOpen}
          onClose={handleMoveToPrivateCreditCardsModalClose}
          onSubmit={handleMoveToPrivateCreditCards}
          title={t(
            'moveCreditCardsToPrivateKeychainModal.title',
            { count: selectedCreditCards.size },
          )}
          description={moveToPrivateCreditCardsModalDescriptionElement}
          list={selectedCreditCardsAsObjects}
          renderField="name"
          renderKey="vaultId"
          isLoading={isMoveToPrivateCreditCardsLoading}
          approvalButtonText="common.move"
          approvalButtonColor="primary"
        />
      )}
      {isMoveToEmployeeCreditCardsModalOpen && (
        <MoveVaultsToEmployeeModal
          isOpen={isMoveToEmployeeCreditCardsModalOpen}
          onClose={handleMoveToEmployeeCreditCardsModalClose}
          vaults={selectedCreditCardsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.CreditCard}
        />
      )}
      {isMoveToGroupCreditCardsModalOpen && (
        <MoveVaultsToGroupModal
          isOpen={isMoveToGroupCreditCardsModalOpen}
          onClose={handleMoveToGroupCreditCardsModalClose}
          vaults={selectedCreditCardsAsObjects}
          onSubmit={resetSelectedRows}
          type={VaultTypeEnum.CreditCard}
        />
      )}
      {isShareCreditCardsModalOpen && (
        <ShareVaultsToGroupModal
          isOpen={isShareCreditCardsModalOpen}
          onClose={handleShareCreditCardsModalClose}
          vaults={selectedCreditCardsAsObjects}
          type={VaultTypeEnum.CreditCard}
          onSubmit={resetSelectedRows}
        />
      )}
      {isCreateCreditCardModalOpen && (
        <CreateCreditCardModal
          isOpen={isCreateCreditCardModalOpen}
          onClose={handleCreateCreditCardModalClose}
          onSubmit={handleCreateCreditCard}
          isLoading={isCreateCreditCardLoading}
        />
      )}
    </PanelContent>
  );
};

export default memo(EmployeeCreditCardsTab);
